import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import LightPoints from '../main/lightPoints';
import { useProgressBar } from '@/components/main/ProgressBar'; // Импортируем хук для прогресс-бара
import { useModeStore } from '@/providers/StateProvider';
import { useSound } from '../../app/context/soundContext';
import { useTranslations } from 'next-intl'; // Import useTranslations

interface MenuComponentProps {
    toggleMenu: () => void;
    openModal: (menuItem: string) => void;
    isOpen: boolean;
    isModalOpen: boolean;
}

export default function MenuComponent({ toggleMenu, openModal, isOpen, isModalOpen }: MenuComponentProps) {
    const { playHoverSound, playClickSound } = useSound();
    const t = useTranslations('Menu'); // Initialize the translation function

    const progress = useProgressBar(); // Используем прогресс-бар
    const setTurboMode = useModeStore.use.setTurboMode(); // Включаем TurboMode
    const setZoomStrength = useModeStore.use.setZoomStrength(); // Используем Zustand для управления ZoomBlur

    const handleClick = (menuItem: string) => {
        // Начинаем прогресс при клике
        progress.start();
        setZoomStrength(100); // Устанавливаем сильный эффект размытия при клике

        openModal(menuItem); // Открываем модальное окно
        // Завершаем прогресс после перехода
        setTimeout(() => {
            progress.done();
        }, 100); // Пример задержки для завершения прогресса
    };

    return (
        <>
            <motion.div
                initial={{ y: '-100%' }}
                animate={{ y: isOpen ? 0 : '-100%' }}
                transition={{ duration: 0.2 }}
                exit={{ y: '-100%', opacity: 0 }}
                className={`menu bg-opacity-50 backdrop-blur-lg  text-white fixed top-[-1px] left-0 w-full h-full z-50 flex items-center justify-center`}
            >
                {/* <div className='absolute w-screen h-screen'>
                    <LightPoints />
                </div> */}
                {isOpen && !isModalOpen && (
                    <div
                        className='absolute top-4 right-4'
                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                        onClick={playClickSound}
                    >
                        <X onClick={toggleMenu} className='cursor-pointer text-white' size={32} />
                    </div>
                )}
                {isOpen && (
                    <div className='z-10'>
                        <ul className='text-center'>
                            {[t('aboutUs')].map((item, index) => (
                                <motion.li
                                    key={index}
                                    className='py-4 text-2xl cursor-pointer'
                                    whileHover={{ scale: 1.2, color: '#848ec8' }} // Анимация при hover
                                    whileTap={{ scale: 0.95 }} // Анимация при клике
                                    transition={{ type: 'spring' }}
                                    onClick={() => handleClick(`menu${index + 1}`)} // Открываем модальное окно и запускаем прогресс
                                >
                                    <p
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={playClickSound}
                                        className='hover:underline underline-offset-4'
                                    >
                                        {item}
                                    </p>
                                </motion.li>
                            ))}
                        </ul>
                    </div>
                )}
            </motion.div>
        </>
    );
}
