'use client';
import { Chrome, Feather, Frame, Slack } from 'lucide-react';
import BoxReveal from '../ui/box-reveal';
import ShinyButton from '../ui/ShinyButton';
import Link from 'next/link';
import { useState, MouseEvent, useCallback } from 'react';
import { useTranslations } from 'next-intl';

function throttle<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
    let lastCall = 0;
    return (...args: Parameters<T>) => {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return func(...args);
    };
}

interface ProductPageProps {
    params: {
        locale: string; // Указываем явный тип для locale
    };
}

const ProductComponent = () => {
    const [rotate, setRotate] = useState({ x: 0, y: 0 });
    // const t = await getTranslations({ locale, namespace: 'Product' });
    const t = useTranslations('Product'); // Use the 'Product' namespace

    const onMouseMove = useCallback(
        throttle((e: MouseEvent<HTMLDivElement>) => {
            const card = e.currentTarget;
            const box = card.getBoundingClientRect();
            const x = e.clientX - box.left;
            const y = e.clientY - box.top;
            const centerX = box.width / 2;
            const centerY = box.height / 2;
            const rotateX = (y - centerY) / 10;
            const rotateY = (centerX - x) / 10;

            setRotate({ x: rotateX, y: rotateY });
        }, 100),
        []
    );

    const onMouseLeave = () => {
        setRotate({ x: 0, y: 0 });
    };

    return (
        <div className='w-full'>
            <div className='customui'>
                <div className='customui__overlay'></div>
                <div className='outline-box_box__k2nYA outline-box_box-dots__Purwq customui__header'>
                    <div className='customui__header-content'>
                        <div className='between-lines_wrapper__QeHD9 between-lines_wrapper-gradient__abLQd'>
                            <div className='badge_badge__1BLy8'>
                                <span className='text_text__diRzx text_text-gradient__A_dhc'> {t('p6')} </span>
                            </div>
                        </div>
                        <div className='ai-showcase-button text_text__diRzx text_text-gradient__A_dhc text_text-bold__DMjUe text_text-align-center__cYXHS text_text-size-h2__FTjRA'>
                            <h2 className='ai-showcase-button-text !text-4xl'>
                                {' '}
                                {t('p2')}&nbsp;{t('p7')}. {t('p2')}&nbsp;{t('p8')}.
                            </h2>
                        </div>
                        <p
                            style={{ marginBlock: '5px' }}
                            className='text_text__diRzx text_text__diRzx text_text-gradient__A_dhc text_text-align-center__cYXHS text_text-size-h5__a3s9K px-1'
                        >
                            {t('p9')}
                        </p>
                    </div>
                </div>
                <div style={{ transform: 'translateX(-1.2495px) translateY(0.0345px) translateZ(0px)' }}>
                    <div className='browser customui__browser'>
                        <div className='browser__header'></div>
                        <div className='browser__content'>
                            <div className='outline-box_box__k2nYA outline-box_box-dots__Purwq customui__card-container'>
                                <div
                                    onMouseMove={onMouseMove}
                                    onMouseLeave={onMouseLeave}
                                    style={{
                                        transform: `perspective(1000px) rotateX(${rotate.x}deg) rotateY(${rotate.y}deg) scale3d(1, 1, 1)`,
                                        transition: 'all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s',
                                    }}
                                >
                                    <div
                                        className='card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'
                                        style={{ '--brand-color': '#663AF3', '--brand-radius': '3px' } as React.CSSProperties}
                                    >
                                        <div className='card_effect__3CjsY' style={{ '--start-angle': '200deg', '--delay': '5s' } as React.CSSProperties}></div>
                                        <div className='card_content__qyjch !h-[500px]'>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                                    <div className='size-full max-w-lg items-center justify-center overflow-hidden pt-8'>
                                                        <p className='text-2xl md:text-3xl font-semibold leading-relaxed text_text-gradient__A_dhc'>
                                                            {t('p10')}
                                                        </p>

                                                        <h2 className='mt-[.5rem] text-[1rem]'>
                                                            <span className='text-[#5046e6]'>{t('p111')}</span>
                                                        </h2>

                                                        <div className='mt-6'>
                                                            <p>
                                                                -&gt; 20+ {t('pm')}
                                                                <span className='font-semibold text-[#5046e6]'> React</span>,
                                                                <span className='font-semibold text-[#5046e6]'> Typescript</span>,
                                                                <span className='font-semibold text-[#5046e6]'> Tailwind CSS</span>,
                                                                <span className='font-semibold text-[#5046e6]'> Framer Motion</span>.
                                                            </p>
                                                        </div>
                                                        <div className='pt-[40px]'>
                                                            <Link
                                                                className='glowing-button_button__183wI glowing-button_button-size-medium__SLv03'
                                                                href='https://www.myru.online'
                                                            >
                                                                <ShinyButton text={t('open')} />{' '}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ transform: 'translateX(-1.04125px) translateY(0.02875px) translateZ(0px)' }}
                                    className='customui__card-outline'
                                ></div>
                            </div>
                            <div style={{ transform: 'translateX(-0.83325px) translateY(0.12675px) translateZ(0px)' }} className='customui_colors'>
                                <div className='outline-box_box__k2nYA outline-box_box-lines__tt4ut outline-box_box-dots__Purwq'>
                                    <div style={{ transform: 'translateX(4.16625px) translateY(-0.63375px) translateZ(0px)' }}>
                                        <div className='card_card__VgpBR card_card-dark__ZxbMX card_card-size-small__dmDk_ card_card-animated__5dDBc card_card-animated-loop__6iL1V color-picker'>
                                            <div
                                                className='card_effect__3CjsY'
                                                style={{ '--start-angle': '515deg', '--delay': '14s' } as React.CSSProperties}
                                            ></div>
                                            <div className='card_content__qyjch'>
                                                <div className='color-picker__content'>
                                                    <p className='text_text__diRzx text_text-muted__2odSz text_text-size-small__hX5Rf'>{t('pcolors')}</p>
                                                    <div className='color-picker__colors'>
                                                        <button
                                                            name='b1'
                                                            aria-label='выбрать цвет'
                                                            type='button'
                                                            className='color-picker__color'
                                                            style={{ '--color-swatch': '#E46D4C' } as React.CSSProperties} // Преобразование стиля в объект
                                                        ></button>
                                                        <button
                                                            name='b2'
                                                            type='button'
                                                            aria-label='выбрать цвет'
                                                            className='color-picker__color color-picker__color--active'
                                                            style={{ '--color-swatch': '#663AF3' } as React.CSSProperties} // Преобразование стиля в объект
                                                        ></button>
                                                        <button
                                                            name='b3'
                                                            type='button'
                                                            aria-label='выбрать цвет'
                                                            className='color-picker__color'
                                                            style={{ '--color-swatch': '#027DEA' } as React.CSSProperties} // Преобразование стиля в объект
                                                        ></button>
                                                        <button
                                                            name='b4'
                                                            type='button'
                                                            aria-label='выбрать цвет'
                                                            className='color-picker__color'
                                                            style={{ '--color-swatch': '#269684' } as React.CSSProperties} // Преобразование стиля в объект
                                                        ></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{ transform: 'translateX(-0.83325px) translateY(0.12675px) translateZ(0px)' }}
                                className='customui_colors md:!top-[200px] w-[61%] md:w-auto !ml-[5px] !md:ml-auto'
                            >
                                <div className='outline-box_box__k2nYA outline-box_box-lines__tt4ut outline-box_box-dots__Purwq '>
                                    <div style={{ transform: 'translateX(4.16625px) translateY(-0.63375px) translateZ(0px)' }}>
                                        <div className='card_card__VgpBR card_card-dark__ZxbMX card_card-size-small__dmDk_ card_card-animated__5dDBc card_card-animated-loop__6iL1V color-picker'>
                                            <div
                                                className='card_effect__3CjsY'
                                                style={{ '--start-angle': '515deg', '--delay': '14s' } as React.CSSProperties}
                                            ></div>
                                            <div className='card_content__qyjch'>
                                                <div className='color-picker__content'>
                                                    <p className='text_text__diRzx text_text-muted__2odSz text_text-size-small__hX5Rf'>{t('plogo')}</p>
                                                    <div className='color-picker__colors'>
                                                        <div className='grid gap-4 grid-cols-2'>
                                                            <div className='p-4'>
                                                                <Chrome />
                                                            </div>
                                                            <div className='p-4'>
                                                                <Feather />
                                                            </div>
                                                            <div className='p-4'>
                                                                <Frame />
                                                            </div>
                                                            <div className='p-4'>
                                                                <Slack />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{ transform: 'translateX(-0.83325px) translateY(0.12675px) translateZ(0px)' }}
                                className='customui_colors md:!left-[740px] md:!top-[80px] w-[90%] md:w-auto'
                            >
                                <div className='outline-box_box__k2nYA outline-box_box-lines__tt4ut outline-box_box-dots__Purwq'>
                                    <div style={{ transform: 'translateX(4.16625px) translateY(-0.63375px) translateZ(0px)' }}>
                                        <div className='card_card__VgpBR card_card-dark__ZxbMX card_card-size-small__dmDk_ card_card-animated__5dDBc card_card-animated-loop__6iL1V color-picker'>
                                            <div
                                                className='card_effect__3CjsY'
                                                style={{ '--start-angle': '515deg', '--delay': '14s' } as React.CSSProperties}
                                            ></div>
                                            <div className='card_content__qyjch'>
                                                <div className='color-picker__content'>
                                                    <p className='text_text__diRzx text_text-muted__2odSz text_text-size-small__hX5Rf'>{t('prounded')}</p>
                                                    <div className='color-picker__colors'>
                                                        <div className='grid gap-4 grid-rows-2'>
                                                            <div className='p-4'>
                                                                <div className='bg-sky-900 p-4 rounded-none flex items-center justify-center'>
                                                                    <p className='text-sm ml-2'>0px ({t('pn')})</p>
                                                                </div>
                                                            </div>

                                                            {/* Элемент с закруглением 4px */}
                                                            <div className='p-4'>
                                                                <div className='bg-fuchsia-900 p-4 rounded-md flex items-center justify-center'>
                                                                    <p className='text-sm ml-2'>4px ({t('ps')})</p>
                                                                </div>
                                                            </div>

                                                            {/* Элемент с закруглением 8px */}
                                                            <div className='p-4'>
                                                                <div className='bg-violet-900 p-4 rounded-md flex items-center justify-center'>
                                                                    <p className='text-sm ml-2'>8px ({t('pmid')})</p>
                                                                </div>
                                                            </div>

                                                            {/* Элемент с закруглением 9999px (полностью круглое) */}
                                                            <div className='p-4'>
                                                                <div className='bg-pink-900 p-4 rounded-md flex items-center justify-center'>
                                                                    <p className='text-sm ml-2'>9999px ({t('pf')})</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='radix-border-overlay'>
                    <div className='between-lines_wrapper__QeHD9 between-lines_wrapper-gradient__abLQd' style={{ marginTop: '46px' }}>
                        <span className='text_text__diRzx text_text-gradient__A_dhc glowing-button_text__eHs9e'>{t('Reviews')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductComponent;
