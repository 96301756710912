import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import AboutPage from '../pages/AboutUs';
import Conference from '../pages/modules/Conference';
import Wall from '../pages/modules/Wall';
import Storage from '../pages/modules/Storage';
import Messeger from '../pages/modules/Messeger';
import Ecom from '../pages/modules/Ecom';
import Blockchain from '../pages/modules/Blockchain';
import Streaming from '../pages/modules/Streaming';

// Пример компонентов для разных пунктов меню
import { useSound } from '@/app/context/soundContext';

interface ModalComponentProps {
    activeMenu: string | null;
    closeModal: () => void;
    isOpen: boolean;
}

export default function ModalComponent({ activeMenu, closeModal, isOpen }: ModalComponentProps) {
    const { playHoverSound, playClickSound } = useSound();

    // Рендерим нужный компонент на основе выбранного пункта меню
    const renderModalContent = () => {
        switch (activeMenu) {
            case 'menu1':
                return <AboutPage />;
            case 'menu2':
                return <Conference />;
            case 'menu3':
                return <Wall />;
            case 'menu4':
                return <Storage />;
            case 'menu5':
                return <Messeger />;
            case 'menu6':
                return <Ecom />;
            case 'menu7':
                return <Blockchain />;
            case 'menu8':
                return <Streaming />;
            default:
                return null;
        }
    };

    return (
        <>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }} // Начальная прозрачность
                    animate={{ opacity: 1 }} // Плавное появление
                    exit={{ opacity: 0 }} // Плавное исчезновение
                    transition={{ duration: 0.5, ease: 'easeInOut' }} // Время анимации
                    className='fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-blur-lg flex items-center h-screen justify-center z-50'
                    // Добавлен класс `backdrop-blur-sm` для эффекта размытия
                >
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }} // Задержка для плавного появления контента
                        className='h-full w-full max-w-max	 p-0 shadow-lg relative rounded-lg'
                    >
                        <div onMouseEnter={playHoverSound} onClick={playClickSound} className='absolute top-7 right-4 z-10'>
                            <X onClick={closeModal} className='cursor-pointer ' size={32} />
                        </div>
                        {/* Рендерим контент для выбранного меню */}
                        {renderModalContent()}
                    </motion.div>
                </motion.div>
            )}
        </>
    );
}
