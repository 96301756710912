import React, { useState, ChangeEvent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { RainbowButton } from '@/components/ui/rainbow-button';
import { InputSpotlightBorder } from '../ui/Input';
import Toast from '../ui/Toast';
import { useSound } from '@/app/context/soundContext';
import { useTranslations } from 'next-intl';

const ContactModal = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState<{ name?: string; phone?: string; email?: string; message?: string }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [toastMessage, setToastMessage] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
    const { playHoverSound, playClickSound } = useSound();
    const t = useTranslations('contact'); // Подключаем переводы

    const validate = () => {
        const newErrors: any = {};
        if (!name) newErrors.name = t('requiredName');
        if (!phone) newErrors.phone = t('requiredPhone');
        if (!email || !/\S+@\S+\.\S+/.test(email)) newErrors.email = t('requiredEmail');
        if (!message) newErrors.message = t('requiredMessage');
        return newErrors;
    };

    const handleChange = (field: string, value: string) => {
        const newErrors = { ...errors };
        if (field === 'name') setName(value);
        if (field === 'phone') setPhone(value);
        if (field === 'email') setEmail(value);
        if (field === 'message') setMessage(value);

        const updatedErrors = validate();
        if (!updatedErrors[field]) {
            //@ts-ignore
            delete newErrors[field];
        }
        setErrors(newErrors);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setIsSubmitting(true);

        try {
            const response = await fetch('/api/send-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name, phone, email, message }),
            });

            if (response.ok) {
                setToastMessage({ message: t('success'), type: 'success' });
                setName('');
                setPhone('');
                setEmail('');
                setMessage('');
                setModalOpen(false); // Закрыть модальное окно
            } else {
                setToastMessage({ message: t('error'), type: 'error' });
            }
        } catch (error) {
            setToastMessage({ message: t('error'), type: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <>
            <div className='w-full flex justify-center'>
                <RainbowButton
                    onClick={(e) => {
                        openModal();
                        //@ts-ignore
                        playClickSound(e);
                    }}
                    // onMouseEnter={playHoverSound}
                >
                    {t('title')}
                </RainbowButton>
            </div>

            {/* Модальное окно */}
            <AnimatePresence>
                {isModalOpen && (
                    <motion.div
                        className='fixed inset-0 bg-black bg-opacity-80 flex items-start md:items-center justify-center z-50'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className='bg-black/80 p-6 rounded-md shadow-lg  md:max-w-md h-full md:h-auto   w-full'
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <h2 className='text-xl font-bold mb-4'>{t('title')}</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-4'>
                                    <label className='block text-sm font-bold mb-2'>{t('name')}</label>
                                    <InputSpotlightBorder
                                        value={name}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('name', e.target.value)}
                                        error={errors.name}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label className='block text-sm font-bold mb-2'>{t('phone')}</label>
                                    <InputSpotlightBorder
                                        value={phone}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('phone', e.target.value)}
                                        error={errors.phone}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label className='block text-sm font-bold mb-2'>{t('email')}</label>
                                    <InputSpotlightBorder
                                        value={email}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('email', e.target.value)}
                                        error={errors.email}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label className='block text-sm font-bold mb-2'>{t('message')}</label>
                                    <InputSpotlightBorder
                                        value={message}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('message', e.target.value)}
                                        error={errors.message}
                                    />
                                </div>
                                <div className='flex justify-end'>
                                    <button
                                        type='button'
                                        className='bg-gray-500 text-white rounded-full px-4 py-2 mr-2 transition duration-300 hover:bg-gray-600'
                                        onClick={closeModal}
                                        // onMouseEnter={playHoverSound}
                                    >
                                        {t('cancel')}
                                    </button>
                                    <button
                                        type='submit'
                                        onClick={playClickSound}
                                        // onMouseEnter={playHoverSound}
                                        className='bg-blue-500 text-white rounded-full px-4 py-2 transition duration-300 hover:bg-blue-600'
                                    >
                                        {isSubmitting ? t('sending') : t('submit')}
                                    </button>
                                </div>
                            </form>
                        </motion.div>
                    </motion.div>
                )}
                {toastMessage && <Toast message={toastMessage.message} onClose={() => setToastMessage(null)} type={toastMessage.type} />}
            </AnimatePresence>
        </>
    );
};

export default ContactModal;
